import { mergeSelectorConfiguration } from 'helper/mergeHelper';
import config, { SelectorConfiguration } from 'selectorConfiguration';

let allianzSelectors: SelectorConfiguration = {
	startup: [],
	'input[type="file"].lastModifiedCheck': {
		1: {
			name: 'LastModifiedCheck',
			requirePath: 'customer/plugins/jquery.lastModifiedCheck',
		},
	},
	'#cameraSlider': {
		1: {
			name: 'InfoPage',
			requirePath: 'plugins/jquery.infoPage',
		},
	},
	'#editMask': {
		1: {
			name: 'TextareaLinebreak',
			requirePath: 'plugins/jquery.textarea-linebreak',
		},
	},
	'input[type="checkbox"].lockcheckbox, input[type="checkbox"].favoriteMark': {
		1: {
			name: 'CustomFormElements',
			requirePath: 'plugins/jquery.customFormElements',
		},
	},
	'div.overviewArea.newsletter': {
		1: {
			name: 'NewsletterEditPreview',
			requirePath: 'plugins/editView/jquery.newsletterEditPreview',
			initializationContext: '#editMask',
		},
	},
	'.resultImage img#superviewimg': {
		1: {
			name: 'Lightbox',
			requirePath: 'plugins/lightbox/jquery.lightbox',
			initializationContext: '.resultSet .resultSetItem',
			configurationName: 'Lightbox',
		},
	},
	'.interactionPortlet .superviewButton, .resultSet.detail .templatePreview.superviewButton': {
		1: {
			name: 'Lightbox',
			requirePath: 'plugins/lightbox/jquery.lightbox',
			initializationContext: '.portlet',
			configurationName: 'Lightbox',
		},
	},
	'.resultSet.superview': {
		1: {
			name: 'SuperviewPreviewSwitch',
			requirePath: 'plugins/jquery.superviewPreviewSwitch',
		},
	},
};

export default mergeSelectorConfiguration(config, allianzSelectors);
