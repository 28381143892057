import { AnchorStatusModule } from '../header/shared/elements/anchor-status/anchor-status.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MsgKeyModule } from '../../shared/translate/msg-key.module';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MsgKeyModule,
		AnchorStatusModule
	],
	exports: [

	],
	declarations: [


	]
})
export class SideNavModule { }
