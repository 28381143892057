import { animate, state, style, transition, trigger } from '@angular/animations';
import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, Renderer2, signal, ViewChild, ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ApplicationState } from '../../../../../shared/store/application-state';
import { NavigationItem } from '../../../../models/navigationItem';
import { SetActiveNavigationItemAction } from '../../../../store/navigation.actions';
import { NavigationQuery } from '../../../../store/navigation.reducer';
import { NavSubItemLevel1Component } from '../nav-sub-item/level-1/nav-sub-item-level-1.component';

@Component({
	selector: 'bas-nav-item',
	templateUrl: './nav-item.component.html',
	styleUrls: ['./nav-item.component.less'],
	animations: [
		trigger('openState', [
			state('open', style({
				transform: 'scaleY(1)',
			})),
			state('closed', style({
				transform: 'scaleY(0)',
				display: 'none',
			})),
			transition('open => closed', animate('0ms cubic-bezier(0.19, 1, 0.22, 1)')),
			transition('closed => open', animate('100ms cubic-bezier(0.19, 1, 0.22, 1)')),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemComponent implements OnInit {
	@Input() item: NavigationItem;
	@ViewChildren(NavSubItemLevel1Component) subItems: QueryList<NavSubItemLevel1Component>;
	@ViewChild('anchor') anchor: ElementRef;

	state: 'closed' | 'open' = 'closed';
	open$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	close$ = new BehaviorSubject(false);
	hasFocus = false;
	isOpen: boolean = false;
	isActive = signal(false);
	escListenFunc: Function;
	clickListenFunc: Function;
	id: string = UUID.UUID();

	constructor(
		private store: Store<ApplicationState>,
		private cdr: ChangeDetectorRef,
		private renderer: Renderer2,
	) {
	}

	private initClosingListener() {
		if (!document.querySelector('#navCloseHandleDiv')) {
			this.addClickElementToDom().onload = () => {
			};
		}

		this.escListenFunc = this.renderer.listen('document', 'keydown', e => {
			if ((e.key === 'Enter' && !e.target.className.includes('nav-item'))
				|| (e.key === 'Enter' && !e.target.closest('.topNavItem')
					.getAttribute('id')
					.includes(this.id))) {
				this.close();
			}
			if (e.key === 'Escape' && e.target.className.includes('nav-item top')) {
				this.close();
			}

			if (e.key === 'Escape' && !e.target.className.includes('nav-item')) {
				this.close();
			}
			const subNavItems = e.target.parentElement.parentElement.parentElement.childNodes;
			//const subNavItems = e.target.nextElementSibling.childNodes;
			var hasVisibleChild = false;
			for (let i = 1; i < subNavItems.length; i++) {
				if (subNavItems[i].childNodes && subNavItems[i].childNodes[0] && subNavItems[i].childNodes[0].childNodes[2].offsetParent) {
					hasVisibleChild = true;
				}
			}
			if (!hasVisibleChild && e.key === 'Escape' && e.target.parentElement.parentElement.parentElement.parentElement.className.includes('topNavItem')) {
				this.close();
				e.target.parentElement.parentElement.parentElement.parentElement.childNodes[1].item?.focus();
			}

		});

		this.clickListenFunc = this.renderer.listen('window', 'click', (e: Event) => {
			const target: any = e.target;
			if (!target.getAttribute('class')) { // close bei klick ins "nichts"
				this.close();
			} else if (target instanceof SVGPathElement) { //muss vor "target.parentElement.className.includes" abgefragt werden, sonst fliegt exception bei Abfrage und element schließt nicht
				this.close();
			} else if ((target.parentElement.className.includes('nav-item top') || target.className.includes('nav-item top')) && !target.closest('.topNavItem')
				.getAttribute('id')
				.includes(this.id)) { // Behandlung für schließen bei klick auf anderes topnavitem
				this.close();
			} else if (!(target.parentElement.className.includes('nav-item')) && !(target.parentElement.className.includes('topNavItem'))
				&& !(target.className.includes('nav-item'))) {
				this.close();
			}
		});
	}

	private addClickElementToDom(): HTMLDivElement {
		const div = document.createElement('div');
		div.id = 'navCloseHandleDiv';
		div.setAttribute('style', 'width:100%; height:100%; position: fixed; z-index: 9999; pointer-events: inherit');
		div.addEventListener('click', this.close, false);
		this.renderer.appendChild(document.querySelector('bas-root'), div);
		return div;
	}

	detachClosingListener() {
		if (this.clickListenFunc) {
			this.clickListenFunc();
			this.escListenFunc();
		}
	}

	focus() {
		this.hasFocus = true;
	}

	blur() {
		this.hasFocus = false;
	}

	ngOnInit(): void {
		this.initActive();

		const toOpenState = open => open ? 'open' : 'closed';
		const close$ = this.close$.pipe(map(toOpenState));
		const open$ = this.open$.pipe(
			debounceTime(175),
			map(toOpenState),
		);

		merge(close$, open$)
			.pipe(distinctUntilChanged())
			.subscribe(s => {
				this.state = <'open' | 'closed'>s;
				this.cdr.markForCheck();
			});
	}

	ngOnDestroy() {
		// remove listener
		this.detachClosingListener();
	}

	initActive() {
		this.store.select(NavigationQuery.getActiveMainItem)
			.subscribe(item => this.isActive.set(item?.id === this.item.id));
	}

	hasChildren(): boolean {
		return this.item.children && this.item.children.length > 0;
	}

	openAndClose() {
		if (document.activeElement.parentElement.classList.contains('topNavItem')) {
			this.isOpen = !this.isOpen;
			this.open$.next(this.isOpen);
			this.close$.next(this.isOpen);
			this.isActive.set(this.isOpen);
			if (this.isOpen) {
				this.initClosingListener();
			} else {
				this.detachClosingListener();
				if (document.querySelector('#navCloseHandleDiv')) {
					document.querySelector('#navCloseHandleDiv')
						.remove();
				}
			}
		}
	}

	close() {
		try {
			this.isOpen = false;
			this.open$.next(false);
			this.close$.next(false);
			this.isActive.set(false);
			this.detachClosingListener();
			if (document.querySelector('#navCloseHandleDiv')) {
				document.querySelector('#navCloseHandleDiv')
					.remove();
			}
		} catch (error) {
			//doNothing
		}
	}

	active() {
		this.store.dispatch(new SetActiveNavigationItemAction(this.item.id));
	}

}
