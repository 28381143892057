<div>
	<div class="tooltip-container"
		 #tooltipContainer>
		<div [@openState]="state"
			 class="tooltip-content"
			 #tooltipContent
			 [style.width.px]="width"
			 [ngClass]="{
		     'has-max-width': hasMaxWidth,
		     'show-top-left': showTooltipTopLeft,
		     'show-bottom-left': showTooltipBottomLeft,
		     'show-bottom-right': showTooltipBottomRight }">
			<ng-content select="bas-icon-nav-tooltip-content"></ng-content>
			<span [innerHTML]="tooltip"></span>
		</div>
	</div>
	<div (click)="openCloseIconNavOnClick()"
		 class="tooltipTriggerContainer"
		 [id]="id">
		<ng-content></ng-content>
	</div>
</div>