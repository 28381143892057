import { animate, state, style, transition, trigger } from '@angular/animations';
import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { ApplicationState } from '../../../../../../shared/store/application-state';
import { NavigationItem } from '../../../../../models/navigationItem';

@Component({
	selector: 'bas-nav-sub-item-level-1',
	templateUrl: './nav-sub-item-level-1.component.html',
	styleUrls: ['./nav-sub-item-level-1.component.less'],
	animations: [
		trigger('openState', [
			state('open', style({
				transform: 'scaleY(1) scaleX(1)'
			})),
			state('closed', style({
				transform: 'scaleY(0) scaleX(0)',
				display: 'none'
			})),

			transition('open => closed', animate('0ms cubic-bezier(0.19, 1, 0.22, 1)')),
			transition('closed => open', animate('100ms cubic-bezier(0.19, 1, 0.22, 1)'))
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavSubItemLevel1Component implements OnInit {

	@Input() item: NavigationItem;
	@Input() counter: any;
	@Output() itemClick: EventEmitter<void> = new EventEmitter();

	@ViewChild('anchor') anchor: ElementRef;
	@ViewChild('menu') menu: ElementRef;

	state: 'closed' | 'open' = 'closed';
	hasFocus = false;
	openToLeft = false;

	listenerFunc: Function;

	keydownListenFunc: Function;
	id: string;

	constructor(private store: Store<ApplicationState>, private cdr: ChangeDetectorRef, private router: Router, private renderer: Renderer2) {
		this.id = UUID.UUID();
	}

	private initClosingListener() {

		this.keydownListenFunc = this.renderer.listen('document', 'keydown', e => {
			if ((e.key === 'Enter' && e.target.className.includes('nav-item') && !e.target?.closest('.nav-item')
					?.getAttribute('id')
					?.includes(this.id)
				&& e.target?.closest('.nav-item')
					?.getAttribute('id')
					?.split(':')[1] <= this.counter.toString())) {
				this.closeSubItem();
				this.detachClosingSubItemListener();
			}
			const subNavItems = e.target?.parentElement?.parentElement?.parentElement?.childNodes;
			var hasVisibleChild = false;
			if (subNavItems) {
			for (let i = 1; i < subNavItems.length; i++) {
				if (subNavItems[i].childNodes[0]?.childNodes[2]?.offsetParent) {
					hasVisibleChild = true;
				}
			}
			}
			if (hasVisibleChild) {
				if (e.key === 'Escape' && e.target.closest('.nav-item').getAttribute('id').split(":")[1] <= this.counter.toString()) {
					this.closeSubItem();
					this.detachClosingSubItemListener();
				}
			} else {
				if (e.key === 'Escape' && e.target.closest('.nav-item')
					?.getAttribute('id')
					?.split(':')[1] - 1 == this.counter.toString()) {
					this.closeSubItem();
					this.detachClosingSubItemListener();
					e.target.parentElement.parentElement.parentElement.parentElement.childNodes[0].focus();
				}
			}

		});

		this.listenerFunc = this.renderer.listen('window', 'click', (e: Event) => {
			const target: any = e.target;
			if (!target.getAttribute('class')) { // close bei klick ins "nichts"
				this.closeSubItem();
				this.detachClosingSubItemListener();
			} else if (target.className.includes('nav-item') && !target.closest('.nav-item')
					?.getAttribute('id')
					?.includes(this.id)
				&& target.closest('.nav-item')
					?.getAttribute('id')
					?.split(':')[1] <= this.counter.toString()
			) { //Behandlung für schließen bei klick auf anderes navItem
				this.closeSubItem();
				this.detachClosingSubItemListener();
			} else if (!(target.className.includes('nav-item')) && !(target.parentElement?.className?.includes('topNavItem'))) {
				this.closeSubItem();
				this.detachClosingSubItemListener();
			}
		}).bind(this);
	}

	detachClosingSubItemListener() {
		if (this.listenerFunc) {
			this.listenerFunc();
			this.keydownListenFunc();
		}
	}

	hasChildren(): boolean {
		return this.item.children && this.item.children.length > 0;
	}

	ngOnInit(): void {
		this.id += ":" + this.counter;
	}
	ngOnDestroy(): void {
		this.detachClosingSubItemListener();
	}

	openAndClose() {
		if (this.state === 'closed') {
			const menu = this.menu.nativeElement as HTMLLIElement;
			const boundingClientRect = menu.getBoundingClientRect();

			this.openToLeft = boundingClientRect.left + (boundingClientRect.width * 2) > window.innerWidth;

			this.state = 'open';
			this.cdr.markForCheck();
			this.initClosingListener();
		} else {
			this.state = 'closed';
			this.detachClosingSubItemListener();
			this.cdr.markForCheck();
		}
	}

	closeSubItem() {
		this.state = 'closed';
		this.cdr.markForCheck();
	}

	closeAndEmit() {
		this.closeSubItem();
		this.itemClick.emit();
	}

	navigate(item: NavigationItem) {
		this.router.navigate([item.url], { queryParams: item.query });
		this.closeAndEmit();
	}
}
