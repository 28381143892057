import { animate, state, style, transition, trigger } from '@angular/animations';
import { EventEmitter } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { ApplicationState } from '../../store/application-state';
import { TooltipPosition, TooltipSpace } from './icon-nav-tooltip-position';

@Component({
	selector: '[bas-icon-nav-tooltip]',
	templateUrl: './icon-nav-tooltip.component.html',
	styleUrls: ['./icon-nav-tooltip.component.less'],
	animations: [
		trigger('openState', [
			state('closed', style({
				height: 0,
				display: 'none'
			})),
			transition('* => closed', animate('0ms cubic-bezier(0.19, 1, 0.22, 1)')),
			transition('closed => *', animate('250ms cubic-bezier(0.19, 1, 0.22, 1)'))
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconNavTooltipComponent {
	/** Der Inhaltstext des Tooltips; kein HTML möglich */
	@Input('bas-icon-nav-tooltip')
	tooltip: string;

	@Output()
	onOpening = new EventEmitter();

	/** Nur von View aus benutzt */
	showTooltipTopLeft = false;
	/** Nur von View aus benutzt */
	showTooltipBottomLeft = false;
	/** Nur von View aus benutzt */
	showTooltipBottomRight = false;
	/** Nur von View aus benutzt */
	//showTooltip$: Observable<boolean>;
	/** Nur von View aus benutzt */
	hasMaxWidth = false;
	/** Nur von View aus benutzt */
	width = null;

	private maxWidth = 500;
	//private showTooltipTrigger$: Subject<boolean> = new Subject();

	@ViewChild('tooltipContent')
	private tooltipContent: ElementRef;
	@ViewChild('tooltipContainer')
	private tooltipContainer: ElementRef;
	state: 'closed' | 'open' = 'closed';
	open$: Subject<boolean> = new Subject();
	close$: Subject<boolean> = new Subject();
	isOpened: boolean = false;
	id: string;
	listenFunc: Function;

	keydownListenFunc: Function;

	constructor(private store: Store<ApplicationState>, private cdr: ChangeDetectorRef, private renderer: Renderer2) {
		this.id = UUID.UUID();
		const toOpenState = open => open ? 'open' : 'closed';
		const close$ = this.close$.pipe(
			debounceTime(200),
			map(toOpenState));
		const open$ = this.open$.pipe(
			debounceTime(175),
			map(toOpenState));

		merge(close$, open$)
			.pipe(distinctUntilChanged())
			.subscribe(s => {
				this.state = <'closed' | 'open'>s;
				this.cdr.markForCheck();
			});
	}

	private initClosingListener() {
		this.listenFunc = this.renderer.listen('window', 'click', (e: Event) => {
			if (this.state != 'closed') {
				const target: any = e.target;
				if (target.tagName.toLocaleLowerCase() === 'a' || !target.getAttribute('class')) { // close bei klick auf links oder ins "nichts"
					this.closeIconNav();
					this.detachClosingListener();
				}
				else if (target instanceof SVGPathElement && !target.closest('.tooltipTriggerContainer')) { // close wenn anderes icon-nav ohne tooltiptrigger geklickt wird
					this.closeIconNav();
					this.detachClosingListener();
				}
				else if (target instanceof SVGPathElement && !target.closest('.tooltipTriggerContainer').getAttribute('id').includes(this.id)) { // close wenn anderes icon-nav mit tooltiptrigger geklickt wird
					this.closeIconNav();
					this.detachClosingListener();
				}
				else if (!(target.getAttribute('class').includes('tooltip-content')) && !(target.getAttribute('class').includes('tooltip-link-Container'))
					&& !(target instanceof SVGPathElement) && !(target.getAttribute('class').includes('nav-icon-tt'))) {
					this.closeIconNav();
					this.detachClosingListener();
				}
			}
		});

		this.keydownListenFunc = this.renderer.listen('document', 'keydown', e => {
			if (e.key === 'Escape') {
				this.closeIconNav();
				this.detachClosingListener();
			}
			if (e.key === 'Enter' && !e.target.firstElementChild.lastElementChild) {
				this.closeIconNav();
				this.detachClosingListener();
			} else if (e.key === 'Enter' && !e.target.firstElementChild.lastElementChild.getAttribute('class').includes('tooltipTriggerContainer')) {
				this.closeIconNav();
				this.detachClosingListener();
			}
		});
	}

	detachClosingListener() {
		this.listenFunc();
		this.keydownListenFunc();
	}

	public openCloseIconNavOnClick() {
		this.isOpened = !this.isOpened;
		if (this.isOpened) {
			this.onOpening.emit('');
			this.calculateTooltipWidthAndPosition();
			this.initClosingListener();
		}
		this.open$.next(this.isOpened);
		this.close$.next(this.isOpened);
	}

	closeIconNav() {
		this.isOpened = false;
		this.open$.next(this.isOpened);
		this.close$.next(this.isOpened);
	}

	private calculateTooltipWidthAndPosition() {
		const tooltipPosition = this.getTooltipPosition();
		if (tooltipPosition.width === this.maxWidth) {
			this.width = null;
		} else {
			this.width = tooltipPosition.width;
		}

		this.showTooltipTopLeft = tooltipPosition.position === TooltipPosition.TOP_LEFT;
		this.showTooltipBottomLeft = tooltipPosition.position === TooltipPosition.BOTTOM_LEFT;
		this.showTooltipBottomRight = tooltipPosition.position === TooltipPosition.BOTTOM_RIGHT;
	}

	private getAvailableSpacesForPositions(): Array<TooltipSpace> {
		const container = this.tooltipContainer.nativeElement.getBoundingClientRect();
		const computedStyles = getComputedStyle(this.tooltipContent.nativeElement);
		const computedPseudoStyles = getComputedStyle(this.tooltipContent.nativeElement, '::before');
		const widthPx = computedStyles.getPropertyValue('width');

		let width = this.maxWidth;
		const paddingTop = parseFloat(computedStyles.getPropertyValue('padding-top'));
		const paddingBottom = parseFloat(computedStyles.getPropertyValue('padding-bottom'));
		const padding = paddingTop + paddingBottom;
		const arrowTopHeight = parseFloat(computedPseudoStyles.getPropertyValue('border-top-width'));
		const arrowBottomHeight = parseFloat(computedPseudoStyles.getPropertyValue('border-bottom-width'));
		const arrowHeight = arrowTopHeight + arrowBottomHeight;

		const containerX = container.left;
		const containerY = container.top;
		const tooltipHeight = parseFloat(computedStyles.getPropertyValue('height')) + padding + arrowHeight;
		const containerPaddingLeft = parseFloat(computedStyles.getPropertyValue('padding-left'));
		const containerPaddingRight = parseFloat(computedStyles.getPropertyValue('padding-right'));
		const containerPaddingX = containerPaddingLeft + containerPaddingRight;
		const maxX = document.body.clientWidth;
		const maxY = window.innerHeight;

		const maxWidthRight = Math.min(maxX - containerX - containerPaddingX, this.maxWidth, width);
		const maxWidthLeft = Math.min(containerX - containerPaddingX + container.width, this.maxWidth, width);

		const fitsY = containerY - tooltipHeight > 0;
		const fitsYToBottom = containerY + tooltipHeight <= maxY;

		let result: Array<TooltipSpace> = [];

		if (fitsY) {
			// Top
			result = [...result,
			{
				position: TooltipPosition.TOP_RIGHT,
				width: maxWidthRight
			},
			{
				position: TooltipPosition.TOP_LEFT,
				width: maxWidthLeft
			}
			];
		}
		if (fitsYToBottom) {
			// Bottom
			result = [...result,
			{
				position: TooltipPosition.BOTTOM_RIGHT,
				width: maxWidthRight
			},
			{
				position: TooltipPosition.BOTTOM_LEFT,
				width: maxWidthLeft
			}
			];
		}

		return result;
	}

	private getTooltipPosition(): TooltipSpace {
		const spaces = this.getAvailableSpacesForPositions();
		if (spaces.length === 0) {
			return {
				position: TooltipPosition.TOP_RIGHT,
				width: this.maxWidth
			};
		}
		const sortedSpaces = spaces.sort(this.sortSpaces);

		return sortedSpaces[0];
	}

	private sortSpaces(a: TooltipSpace, b: TooltipSpace): number {
		const difference = b.width - a.width;
		if (difference === 0) {
			return a.position - b.position;
		}

		return difference;
	}
}
