import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'bas-error',
	template: `<span>{{error}}<ng-content></ng-content></span>`,
	styleUrls: ['./error.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent {
	@Input()
	error = '';

	constructor(public cdr: ChangeDetectorRef) {}
}
