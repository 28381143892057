<li class="topNavItem"
	[id]="id"
	(keydown.enter)="openAndClose()"
	[class.active]="isActive()">
	<a *ngIf="hasChildren(); else noChildren"
	   (click)="openAndClose()"
	   tabindex="0"
	   #anchor
	   bas-anchor-status
	   [parameters]="item.parameters"
	   class="nav-item top">
		<span>{{ item.name || (item.nameKey | msgKey) }}</span>
	</a>
	<ng-template #noChildren>
		<a (click)="openAndClose()"
		   tabindex="0"
		   #anchor
		   bas-anchor-status
		   [parameters]="item.parameters"
		   [routerLink]="item.url"
		   [queryParams]="item.query"
		   class="nav-item top">
			<span>{{ item.name || (item.nameKey | msgKey) }}</span>
		</a>
	</ng-template>
	<ul *ngIf="hasChildren()"
		[@openState]="state">
		<bas-nav-sub-item-level-1 *ngFor="let subItem of item.children"
								  [item]="subItem"
								  [counter]="1"
								  (itemClick)="close()"></bas-nav-sub-item-level-1>
	</ul>
</li>