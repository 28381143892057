<li #menu>
	<a
			[id]="id"
			#anchor
			class="nav-item"
			tabindex="0"
			bas-anchor-status
			[parameters]="item.parameters"
			(keydown.enter)="hasChildren() ? openAndClose() : navigate(item)"
			(click)="hasChildren() ? openAndClose() : navigate(item)"
	>
		<span
				[id]="id"
				class="nav-item"
		>{{ item.name || (item.nameKey | msgKey) }}</span>
	</a>
	<ul *ngIf="hasChildren()"
		[@openState]="state"
		class="mat-elevation-z9"
		[ngClass]="{ openToLeft: openToLeft }">
		<bas-nav-sub-item-level-1 *ngFor="let subItem of item.children; let i = index"
								  [item]="subItem"
								  [counter]="counter+1"
								  (itemClick)="closeAndEmit()"></bas-nav-sub-item-level-1>
	</ul>
	<div *ngIf="hasChildren()"
		 class="subNavigationMarker"></div>
</li>