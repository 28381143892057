import { Component, Input, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { zip } from 'rxjs';
import { ConfigService } from '../shared/services/config.service';
import { MsgKeyModule } from '../shared/translate/msg-key.module';

interface VidflarePreview {
	on(event: string, callback: Function): void;

	prepareImage(imgUrl: string): Promise<any>;

	goToFrame(frame: number): void;

	setDynamicData(data: any): void;
}

declare global {
	interface Window {
		createVidflarePreview(config): VidflarePreview;
	}
}

const VIDFLARE_LOADER_ID = 'vidflare-loader';

@Component({
	templateUrl: './video-preview.component.html',
	styleUrls: ['./video-preview.component.less'],
	encapsulation: ViewEncapsulation.None,
	providers: [],
	standalone: true,
	imports: [
		MsgKeyModule,
	],
})
export class VideoPreviewComponent implements OnInit, OnDestroy {

	@Input({ required: true })
	projectid: string;
	@Input({ required: true })
	layoutid: string;

	private _modifications: Record<string, string>;
	@Input({ required: true })
	set modifications(mods: string) {
		this._modifications = JSON.parse(mods);
	}

	vidflarePreviewObj: any;

	constructor(private configService: ConfigService, private renderer: Renderer2) {
		zip(this.configService.getVidflareUrl(), this.configService.getVidflareAccessToken())
			.subscribe(([url, accessToken]) => this.addJsToHead(url, accessToken));
	}

	ngOnInit(): void {
		document.removeAllListeners('vidflareReadyToBeInitialized');
		document.addEventListener('vidflareReadyToBeInitialized', () => {

			this.vidflarePreviewObj = window.createVidflarePreview({
				el: '#vidflare-loading',
				vidflareProps: {
					projectUuid: this.projectid,
					layoutUuid: this.layoutid,
				},
				token: document.getElementById(VIDFLARE_LOADER_ID)
					.getAttribute('data-token'),
			});

			this.vidflarePreviewObj.on('loaded', () => {
				const uniqueIdImageRegex = /^Image \d+$/; // uniqueIds müssen fix "Image 1", "Image 2", etc heißen
				const imageEntries = Object.entries(this._modifications)
					.filter(([uniqueId, url]) => uniqueIdImageRegex.test(uniqueId));

				const data = { ...this._modifications };
				imageEntries.forEach(([uniqueId, url]) => data[uniqueId] = undefined);
				this.changeData(data);

				const selectedImages = imageEntries.filter(([uniqueId, url]) => url.includes('://'));
				if (!selectedImages.length) {
					return;
				}
				Promise.all(selectedImages.map(([uniqueId, url]) => this.vidflarePreviewObj.prepareImage(url)))
					.then(responses => {
						const data = { ...this._modifications };
						responses.forEach((res, index) => {
							data[selectedImages[index][0]] = res;
						});
						this.changeData(data);
					});
			});
		});
	}

	ngOnDestroy() {
		this.vidflarePreviewObj.remove();
	}

	private changeData(data) {
		this.vidflarePreviewObj.setDynamicData(data);
	}

	private addJsToHead(src: string, accessToken: string) {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.id = VIDFLARE_LOADER_ID;
		script.src = src + '/standalone/loader';
		script.setAttribute('data-token', accessToken);
		this.renderer.appendChild(document.head, script);
	}
}