<ng-container *ngIf="!isMobile; else mobileAnsicht">
	<div class="desktop">
		<div class="page">
			<bas-nav-header></bas-nav-header>
			<div class="content">
				<div class="content-area-container">
					<div class="content-area">
						<router-outlet></router-outlet>
						<div class="links"
							 *ngIf="showMainNav() && canSeeDebugLinks()">
							<a bas-link
							   class="debugItem"
							   routerLink="/service/uilab-branching-mask.do">UI Lab</a>
						</div>
					</div>
					<div class="back-to-top-container"
						 *ngIf="showMainNav()">
						<div tabindex="0"
							 class="backToTopDiv"
							 (keydown.enter)="scrollToTop()">
							<div class="back-to-top"
								 (click)="scrollToTop()"></div>
						</div>
					</div>
					<bas-nav-footer></bas-nav-footer>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #mobileAnsicht>
	<div class="mobile-page"
		 [class.mobile-login]="isLoginPage()">
		<bas-nav-header *ngIf="showNavHeader()"></bas-nav-header>
		<bas-mobile-nav *ngIf="showMobileNav()"></bas-mobile-nav>
		<router-outlet></router-outlet>
		<bas-nav-footer *ngIf="showNavFooter()"></bas-nav-footer>
	</div>
</ng-template>