<div bas-icon-nav-tooltip
	 tabindex="0"
	 (onOpening)="getFavoriten()"
	 (keydown.enter)="triggerClick($event)">
	<div class="icon-container"
		 bas-tooltip="Favoriten"
		 [tabbar]=false>
		<mat-icon svgIcon="allianz_favoriten"
				  class="default-icon"></mat-icon>
		<mat-icon svgIcon="allianz_favoriten_hover"
				  class="hover_icon"></mat-icon>
	</div>
	<bas-icon-nav-tooltip-content class="nav-icon-tt">
		<div class="tooltip-link-Container"
			 *ngIf="!favoriten()?.length; else hasFavoriten">{{'text.no.favourites' | msgKeyCached}}</div>
		<ng-template #hasFavoriten>
			<div *ngFor="let navItem of favoriten()">
				<a [routerLink]="navItem.url">{{navItem.name}}</a>
			</div>
		</ng-template>
	</bas-icon-nav-tooltip-content>
</div>