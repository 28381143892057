import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bas-icon-nav-tooltip-content',
	template: '<ng-content></ng-content>',
	animations: [
		trigger('openState', [
			state('closed', style({
				height: 0,
				display: 'none'
			})),
			transition('* => closed', animate('0ms cubic-bezier(0.19, 1, 0.22, 1)')),
			transition('closed => *', animate('250ms cubic-bezier(0.19, 1, 0.22, 1)'))
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconNavTooltipContentComponent { }
