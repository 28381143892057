import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../shared/store/application-state';
import { Logout } from '../../shared/store/logout.action';
import { NavigationQuery } from '../store/navigation.reducer';

@Component({
	selector: 'bas-nav-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.less', './nav-icon/nav-icon.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {

	private allItems = this.store.selectSignal(NavigationQuery.items);
	items = computed(() => this.allItems()
		.filter(item => !item.parameters['showInAltNav']));
	altItems = computed(() => this.allItems()
		.filter(item => item.parameters['showInAltNav']));
	showMainNav = this.store.selectSignal(NavigationQuery.showMainNav);

	searchFieldClass = signal<string>(null);

	constructor(
		private store: Store<ApplicationState>,
		private router: Router,
	) {
	}

	checkState(state: string) {
		this.searchFieldClass.set(state === 'open' ? 'closed' : 'open');
	}

	triggerClick(event: {
		target: HTMLDivElement;
	}) {
		$(event.target)
			.find('.amp-logo')[0].click();
	}

	triggerClickOpenSearch(event: {
		target: HTMLDivElement;
	}) {
		if ($(event.target)
			.find('.searchOpenButton').length > 0) {
			$(event.target)
				.find('.searchOpenButton')[0].click();
		}
	}

	triggerLogoutClick(event: {
		target: HTMLInputElement;
	}) {
		$(event.target)
			.find('a')[0].click();
	}

	logout() {
		this.store.dispatch(new Logout);
		this.router.navigateByUrl('/logout.do');
	}
}
