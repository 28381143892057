import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ApplicationState } from '../../../shared/store/application-state';
import { NavigationItem } from '../../models/navigationItem';
import { SetActiveNavigationItemAction } from '../../store/navigation.actions';
import { NavigationQuery } from '../../store/navigation.reducer';

@Component({
	selector: 'bas-nav-quick',
	templateUrl: './quick-navigation.component.html',
	styleUrls: ['./quick-navigation.component.less'],
	animations: [
		trigger('openState', [
			state('closed', style({
				height: 0,
			})),
			transition('* => closed', animate('0ms cubic-bezier(0.19, 1, 0.22, 1)')),
			transition('closed => *', animate('250ms cubic-bezier(0.19, 1, 0.22, 1)')),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickNavComponent {

	items = computed(() => this.store.selectSignal(NavigationQuery.items)()
		.filter(item => item.url && !item.parameters['showInAltNav'] && item.id !== 'home'));

	state: 'closed' | 'open' = 'closed';
	open$: Subject<boolean> = new Subject();
	close$: Subject<boolean> = new Subject();
	isOpened: boolean = false;
	clickListenerFunc: Function;

	keydownListenFunc: Function;

	constructor(private store: Store<ApplicationState>, private cdr: ChangeDetectorRef, private renderer: Renderer2) {
		const toOpenState = open => open ? 'open' : 'closed';
		const close$ = this.close$.pipe(
			debounceTime(200),
			map(toOpenState),
		);
		const open$ = this.open$.pipe(
			debounceTime(175),
			map(toOpenState),
		);

		merge(close$, open$)
			.pipe(distinctUntilChanged())
			.subscribe(s => {
				this.state = <'closed' | 'open'>s;
				this.cdr.markForCheck();
			});
	}

	private initClosingListener() {

		this.keydownListenFunc = this.renderer.listen('document', 'keydown', e => {
			if ((e.key === 'Enter' && !e.target.parentElement.className.includes('quick-nav'))) {
				this.closeQuickNav();
			}
		});

		this.clickListenerFunc = this.renderer.listen('window', 'click', (e: Event) => {
			const target: any = e.target;
			if (
				target instanceof SVGPathElement
				|| !target.getAttribute('class') // close bei klick ins "nichts"
				|| !['quick-nav-trigger', 'quick-nav-background', 'quick-nav-item', 'quick-nav-container', 'quick-nav-sub-item']
					.some(className => target.className.includes(className))
			) {
				this.closeQuickNav();
			}
		})
			.bind(this);
	}

	detachClosingQuicknavListener() {
		this.clickListenerFunc();
		this.keydownListenFunc();
	}

	openCloseQuickNavOnClick(event) {
		if (event.target.className.includes('quick-nav-trigger') || event.target.className.includes('quick-nav-background') ||
			(event instanceof KeyboardEvent && event.key === 'Enter')) {
			this.isOpened = !this.isOpened;
			if (this.isOpened) {
				this.initClosingListener();
			} else {
				this.detachClosingQuicknavListener();
			}
			this.open$.next(this.isOpened);
			this.close$.next(this.isOpened);
		}
	}

	closeQuickNav() {
		this.isOpened = false;
		this.open$.next(false);
		this.close$.next(false);
		this.detachClosingQuicknavListener();
	}

	onClick(item: NavigationItem) {
		this.closeQuickNav();
		this.store.dispatch(new SetActiveNavigationItemAction(item.id));
		this.state = 'closed'; // Set state to closed directly to ignore debounce
		this.cdr.markForCheck();
	}
}
