import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TooltipModule } from '../../shared/generic-compounds/tooltip/tooltip.module';
import { MsgKeyModule } from '../../shared/translate/msg-key.module';
import { HeaderComponent } from './header.component';
import { QuickNavComponent } from './quick-navigation/quick-navigation.component';
import { AnchorStatusModule } from './shared/elements/anchor-status/anchor-status.module';
import { NavItemComponent } from './shared/elements/nav-item/nav-item.component';
import { NavSearchFieldComponent } from './shared/generic-compounds/nav-search-field/nav-search-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NavSearchHighlightPipe } from './shared/generic-compounds/nav-search-field/nav-search-highlight.pipe';
import { NavIconComponent } from './nav-icon/nav-icon.component';
import { NavSubItemLevel1Component } from './shared/elements/nav-sub-item/level-1/nav-sub-item-level-1.component';
import { ButtonModule } from '../../shared/elements/button/button.module';
import { FavNavIconComponent } from './fav-nav-icon/fav-nav-icon.component';
import { IconNavTooltipModule } from '../../shared/generic-compounds/icon-nav-tooltip/icon-nav-tooltip.module';

@NgModule({
	declarations: [
		HeaderComponent,
		QuickNavComponent,
		NavItemComponent,
		NavSubItemLevel1Component,
		NavSearchFieldComponent,
		NavSearchHighlightPipe,
		NavIconComponent,
		FavNavIconComponent
	],
	imports: [
		CommonModule,
		MsgKeyModule,
		BrowserAnimationsModule,
		MatIconModule,
		ReactiveFormsModule,
		RouterModule,
		TooltipModule,
		IconNavTooltipModule,
		AnchorStatusModule,
		ButtonModule
	],
	exports: [
		HeaderComponent,
		QuickNavComponent
	]
})
export class HeaderModule { }
