<div class="searchOpenButton"
	 #searchOpenButton
	 (click)="openCloseSearchOnClick();sendState();searchInput.focus()"
	 (keydown.esc)="closeSearch()">
	<div class="searchIcon"
		 #searchIcon>
		<mat-icon (click)="sendState();searchInput.focus()"
				  svgIcon="allianz_suche"
				  class="default-icon-searchicon">
		</mat-icon>
		<mat-icon (click)="sendState();searchInput.focus()"
				  svgIcon="allianz_suche_hover"
				  class="hover_icon_searchicon">
		</mat-icon>
	</div>{{ 'image.nav.search.alt' | msgKey | uppercase }}

	<div [@openState]="state"
		 class="searchOverlay"
		 (click)="$event.stopPropagation()">
		<div class="searchHeader">
			<mat-icon svgIcon="allianz_suche"
					  class="searchIcon">
			</mat-icon>
			<input tabindex="1"
				   type="text"
				   #searchInput
				   (keydown.enter)="search()"
				   [formControl]="term"
				   [title]="'label.searchfield.overlay' | msgKey"
				   [placeholder]="'label.searchfield.overlay' | msgKey">
			<div tabindex="1"
				 class="buttonMargin"
				 (keydown.enter)="search()">
				<button bas-button
						class="searchButton"
						color="primary"
						[specificTabindex]="-1"
						(throttledClick)="search()">{{'button.search.alt'|msgKeyCached}}</button>
			</div>
		</div>
		<div *ngIf="autoComplete()">
			<div class="searchList">
				<div class="search-results">
					<div class="search-entry"
						 tabindex="0"
						 *ngFor="let term of autoComplete()"
						 (click)="selectAutocomplete(term)"
						 (keydown.enter)="selectAutocomplete(term)"
						 [attr.data-term]="term">
						<div>{{term}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>