import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { NavigationItem } from '../../models/navigationItem';
import { NavigationService } from '../../navigation.service';

@Component({
	selector: 'bas-fav-nav-icon',
	templateUrl: './fav-nav-icon.component.html',
	styleUrls: ['../nav-icon/nav-icon.component.less', './fav-nav-icon.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavNavIconComponent {

	favoriten = signal<Array<NavigationItem>>(null);

	constructor(private navigationService: NavigationService) {
	}

	getFavoriten() {
		this.navigationService.getFavoriten()
			.subscribe(favoriten => this.favoriten.set(favoriten));
	}

	triggerClick(event: {
		target: HTMLInputElement;
	}) {
		$(event.target)
			.find('.tooltipTriggerContainer')[0].click();
	}

}
