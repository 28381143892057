import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2,
	signal, ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Component({
	selector: 'bas-nav-search-field',
	templateUrl: './nav-search-field.component.html',
	styleUrls: ['./nav-search-field.component.less'],
	animations: [
		trigger('openState', [
			state('closed', style({
				height: 0,
			})),
			transition('open => closed', animate('750ms cubic-bezier(0.19, 1, 0.22, 1)')),
			transition('closed => open', animate('750ms cubic-bezier(0.19, 1, 0.22, 1)')),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavSearchFieldComponent implements OnInit, OnDestroy {

	@Output() stateToEmit = new EventEmitter<string>();

	sendState(): void {
		this.stateToEmit.emit(this.state);
	}

	term: FormControl = new FormControl();
	autoComplete = signal<Array<string>>(null);

	state: 'closed' | 'open' = 'closed';
	open$: Subject<boolean> = new Subject();
	close$: Subject<boolean> = new Subject();

	@ViewChild('searchOpenButton')
	searchOpenButton: ElementRef;

	@ViewChild('searchIcon')
	searchIcon: ElementRef;
	isOverlayOpen: boolean = false;
	private searchUrl = '/workflow.do?from=menu' +
		'&to=booking-choose' +
		'&byPath(navigation.currentNodeByPath)=/tree=booking/' +
		'&byPath(navigation.currentNode.tree.node(booking).filterValue(fulltextsearch_filter))=';

	searchListenerFunc: Function;

	keydownListenFunc: Function;

	constructor(
		private router: Router,
		private http: HttpClient,
		private cdr: ChangeDetectorRef,
		private renderer: Renderer2,
		private destroyRef: DestroyRef,
	) {
		const toOpenState = open => open ? 'open' : 'closed';
		const close$ = this.close$.pipe(
			debounceTime(200),
			map(toOpenState),
		);
		const open$ = this.open$.pipe(
			debounceTime(175),
			map(toOpenState),
		);

		merge(close$, open$)
			.pipe(distinctUntilChanged())
			.subscribe(s => {
				this.state = <'closed' | 'open'>s;
				this.cdr.markForCheck();
			});
	}

	ngOnInit() {
		this.term.valueChanges
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				debounceTime(400),
				distinctUntilChanged(),
				switchMap(term => this.getAutoCompletionResults(term)),
			)
			.subscribe(results => this.autoComplete.set([...results].slice(0, 5)));
	}

	private initClosingSearchListener() {
		this.keydownListenFunc = this.renderer.listen('document', 'keydown', e => {
			if (e.key === 'Enter' && !e.target.className.includes('searchField')) {
				this.closeSearch();
				this.detachClosingSearchListener();
			}
		});

		this.searchListenerFunc = this.renderer.listen('window', 'click', (e: Event) => {
			const target: any = e.target;
			if (typeof target.parentElement.className.includes === 'undefined') {
				this.closeSearch();
				this.detachClosingSearchListener();
			} else {
				if (target !== this.searchOpenButton.nativeElement && target !== this.searchIcon.nativeElement
					&& !(target.parentElement.className.includes('hover_icon_searchicon')
						&& !(target.parentElement.className.includes('default-icon-searchicon')))) {
					this.closeSearch();
					this.detachClosingSearchListener();
				}
			}
		});
	}

	detachClosingSearchListener() {
		this.searchListenerFunc();
		this.keydownListenFunc();
	}

	ngOnDestroy() {
		this.searchListenerFunc?.();
	}

	getAutoCompletionResults(term: string): Observable<Array<string>> {
		const type = 'templatefulltextsearch';

		if (!term || term.length < 3) {
			return of([]);
		}

		return this.http.get<Array<string>>('externalservices/autocompletion.do', { params: { type, term } });
	}

	openCloseSearchOnClick() {
		this.isOverlayOpen = !this.isOverlayOpen;
		if (this.isOverlayOpen) {
			this.initClosingSearchListener();
		} else {
			this.detachClosingSearchListener();
		}
		this.open$.next(this.isOverlayOpen);
		this.close$.next(this.isOverlayOpen);
		this.sendState();
	}

	selectAutocomplete(term: string) {
		this.term.setValue(term);
		this.search();
	}

	search() {
		this.closeSearch();
		const term = this.term.value;
		this.term.setValue('');
		const url = this.searchUrl + encodeURIComponent(term || '');
		this.router.navigate([url]);

	}

	closeSearch() {
		this.isOverlayOpen = false;
		this.open$.next(false);
		this.close$.next(false);
		this.detachClosingSearchListener();
		if (this.state === 'open') {
			this.sendState();
		}
	}
}
