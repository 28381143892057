<div [bas-tooltip]="altNavItem.nameKey | msgKeyCached"
	 [tabbar]=false>
	<div *ngIf="altNavItem.children.length > 0; else topLevelNavItem"
		 bas-icon-nav-tooltip
		 class="icon-container"
		 tabindex="0"
		 (keydown.enter)="triggerClick($event)">

		<mat-icon svgIcon="{{altNavItem.icon}}"
				  class="default-icon"></mat-icon>
		<mat-icon svgIcon="{{altNavItem.icon + '_hover'}}"
				  class="hover_icon"></mat-icon>
		<bas-icon-nav-tooltip-content class="nav-icon-tt">
			<div class="tooltip-link-Container"
				 *ngFor="let link of altNavItem.children">
				<a tabindex="0"
				   [routerLink]="link.url"
				   [queryParams]="link.query">{{link.nameKey | msgKeyCached}}</a>
			</div>
		</bas-icon-nav-tooltip-content>
	</div>

	<ng-template #topLevelNavItem>
		<div class="icon-container"
			 tabindex="0"
			 (keydown.enter)="triggerNavigate($event)">
			<a [routerLink]="altNavItem.url"
			   [queryParams]="altNavItem.query"
			   tabindex="-1">
				<mat-icon svgIcon="{{altNavItem.icon}}"
						  class="default-icon"></mat-icon>
				<mat-icon svgIcon="{{altNavItem.icon + '_hover'}}"
						  class="hover_icon"></mat-icon>
			</a>
		</div>
	</ng-template>
</div>