<a *ngIf="user(); else notloggedin"
   class="ajaxIgnore"
   id="document"
   href="https://www.allianz.de/impressum/"
   target="_blank">
	{{"frame.footer.impressum" | msgKeyCached}}
</a>

<ng-template #notloggedin>
	<ng-container *ngIf="('showLinkToImpressumBeforeLogin' | feature | async)">
		<a class="ajaxIgnore"
		   id="document"
		   href="https://www.allianz.de/impressum/"
		   target="_blank">
			{{"frame.footer.impressum" | msgKeyCached}}
		</a>
	</ng-container>
</ng-template>