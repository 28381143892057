import { HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { SideNavModule } from './navigation/side-nav/side-nav.module';
import { sharedDeclarations, sharedProviders } from './shared-dependencies';
import { sharedModules } from './shared-modules';
import { DirectivesModule } from './shared/directives/directives.module';

@NgModule({
	declarations: [
		...sharedDeclarations,
	],
	imports: [
		...sharedModules,
		SideNavModule,
		HttpClientXsrfModule,
		DirectivesModule,
	],
	providers: [
		...sharedProviders,
		{ provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
