import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationItem } from '../../models/navigationItem';

@Component({
	selector: 'bas-nav-icon',
	templateUrl: './nav-icon.component.html',
	styleUrls: ['./nav-icon.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavIconComponent {

	@Input()
	altNavItem: NavigationItem;

	constructor() {
	}

	triggerClick(event: {
		target: HTMLInputElement;
	}) {
		$(event.target)
			.find('.tooltipTriggerContainer')[0].click();
	}

	triggerNavigate(event: {
		target: HTMLInputElement;
	}) {
		$(event.target)
			.find('a')[0].click();
	}
}
