<header>
	<ng-container *ngIf="showMainNav(); else notLoggedIn">
		<div class="meta-navigation">
			<div id="logo-name">
				<div class="logo-loggedin">
					<a routerLink="/workflow.do?from=menu&to=home"
					   tabindex="0">
						<img src="page/style/img/AZ_Logo_positive_RGB.png">
					</a>
				</div>
				<div class="vertical-line"></div>
				<div class="application-name-loggedin">
					{{'page.applicationName' | msgKey}}
				</div>
			</div>

			<nav id="icon-nav">
				<div class="amp-logo"
					 tabindex="0"
					 (keydown.enter)="triggerClick($event)">
					<a class="amp-logo"
					   tabindex="-1"
					   routerLink="/workflow.do?from=menu&to=home">
						<img src="page/style/img/amp-logo.svg"
							 id="amp-logo">
					</a>
				</div>
				<bas-fav-nav-icon></bas-fav-nav-icon>
				<bas-nav-icon *ngFor="let altNavItem of altItems()"
							  [altNavItem]="altNavItem"></bas-nav-icon>

				<div class="logout"
					 [bas-tooltip]="'image.metanav.logout.alt' | msgKey"
					 (keydown.enter)="triggerLogoutClick($event)">
					<a routerLink="/logout.do"
					   tabindex="-1"
					   (click)="logout()">
						<mat-icon svgIcon="allianz_logout"
								  class="default-icon"></mat-icon>
						<mat-icon svgIcon="allianz_logout_hover"
								  class="hover_icon"></mat-icon>
					</a>
				</div>
			</nav>

		</div>

		<nav>
			<div class="main-navigation primaryCiColor">
				<bas-nav-quick class="quick-nav"></bas-nav-quick>
				<ul>
					<ng-container *ngFor="let topItems of items(); last as lastitem">
						<ng-container *ngIf="topItems.name === 'root'; else spaceOrNormal">
							<bas-nav-item *ngFor="let item of topItems.children"
										  [item]="item"></bas-nav-item>
						</ng-container>
						<ng-template #spaceOrNormal>
							<ng-container *ngIf="topItems.id==='spacer'; else normal">
								<div *ngIf="!lastitem"
									 class="vertical-line"></div>
							</ng-container>
						</ng-template>
						<ng-template #normal>
							<bas-nav-item [item]="topItems"></bas-nav-item>
						</ng-template>
					</ng-container>
				</ul>

				<div class="searchField"
					 [ngClass]="searchFieldClass"
					 tabindex="0"
					 (keydown.enter)="triggerClickOpenSearch($event)">
					<bas-nav-search-field (stateToEmit)='checkState($event)'></bas-nav-search-field>
				</div>
			</div>
		</nav>
	</ng-container>

	<ng-template #notLoggedIn>
		<div class="meta-navigation">
			<div id="logo-name">
				<div class="logo-loggedin">
					<img src="page/style/img/AZ_Logo_positive_RGB.png">
				</div>
				<div class="vertical-line"></div>
				<div class="application-name-loggedin">
					{{'page.applicationName' | msgKey}}
				</div>
			</div>

		</div>
	</ng-template>
</header>